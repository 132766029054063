import React from 'react';
import { motion } from 'framer-motion';
import bg1 from '../assets/img4.jpg';

const Services = () => {
  const images = [bg1];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  const services = [
    "General Home Repairs",
    "Plumbing Services",
    "Electrical Services",
    "Painting and Decorating",
    "Furniture Assembly",
    "Appliance Installation",
    "Flooring and Tiling",
    "Window and Door Repair",
    "Drywall Repair",
    "Home Patching",
    "Roofing Repairs",
  ];

  return (
    <div className="text-center" style={{ fontFamily: 'Cabin' }}>
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-[#F6CB3D] text-4xl font-bold mb-4">Our Services</h1>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
            className="inline-block mb-4"
          >
            <a href="tel:+12534314507" className="bg-[#2A6B84] text-[#F6CB3D] px-4 py-2 rounded font-bold">
              <span>📞</span><span>Call us at (253) 431-4507 to Book</span>
            </a>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-4xl mx-auto text-[#F6CB3D] px-4 pt-4 pb-10">
        <p className="text-lg md:text-xl text-[#2A6B84]">
          With more than 20 years of experience in home repairs, I can fix, repair & installing anything, including and plumbing & electrical needs you may have. I am a licensed and & insured contractor, and I guarantee all my work. I am a one-stop shop for all your home repair needs. Examples of Services I offer are (not limited to):
        </p>
        <div className="grid grid-cols-1 gap-8 pt-4">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-[#2A6B84] bg-opacity-75 rounded-lg p-6 text-lg md:text-xl font-semibold shadow-lg"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.3 } }}
              
            >
              {service}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
