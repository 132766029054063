import React, { useState } from 'react';
import { motion } from 'framer-motion';

import bg1 from '../assets/img4.jpg';
import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';
import img4 from '../assets/img4.jpg';
import img5 from '../assets/img5.jpg';
import img6 from '../assets/img6.jpg';
import img7 from '../assets/img7.jpg';
import img8 from '../assets/img8.jpg';
import img9 from '../assets/img9.jpg';


const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9
];

const Gallery = () => {
    const bgimages = [bg1];
    const bgNum = Math.floor(Math.random() * bgimages.length);
    const bgImage = bgimages[bgNum];

    const [selectedImage, setSelectedImage] = useState(null);

    const openImage = (index) => {
        setSelectedImage(index);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <motion.div
                className="relative h-96 bg-cover bg-center"
                style={{ backgroundImage: `url(${bgImage})` }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
                    <h1 className="text-[#F6CB3D] text-4xl font-bold mb-4 px-6">Past Work</h1>
                    <motion.div
                        whileHover={{
                            scale: 1.1,
                            translateZ: 50,
                            perspective: 1000,
                            boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                            transition: { duration: 0.2 }
                        }}
                        className="inline-block mb-4"
                    >
                        <a href="tel:+12534314507" className="bg-[#2A6B84] text-[#F6CB3D] px-4 py-2 rounded font-bold">
                            <span>📞</span><span>Call us at (253) 431-4507 to Book</span>
                        </a>
                    </motion.div>
                </div>
            </motion.div>
            <div className="container mx-auto px-4 py-8 flex-1">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {images.map((image, index) => (
                        <motion.div
                            key={index}
                            className="border"
                            whileHover={{
                                scale: 1.1,
                                translateZ: 50,
                                perspective: 1000,
                                boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)",
                                transition: { duration: 0.2 }
                            }}
                            whileTap={{ scale: 0.95 }}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            onClick={() => openImage(index)}
                        >
                            <img src={image} alt={`Gallery ${index}`} className="w-full h-96 object-cover" />
                        </motion.div>
                    ))}
                </div>
            </div>

            {selectedImage !== null && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    onClick={closeImage}
                >
                    <motion.img
                        src={images[selectedImage]}
                        alt={`Gallery ${selectedImage}`}
                        className="max-w-full max-h-full px-4 sm:px-3"
                        initial={{ y: "-100vh" }}
                        animate={{ y: 0 }}
                        transition={{ type: "spring", stiffness: 100, damping: 20 }}
                    />
                </motion.div>
            )}
        </div>
    );
};

export default Gallery;
