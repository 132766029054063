import {React}  from 'react';
import {FaEnvelope} from 'react-icons/fa';

const Footer = () => {
    return (
      <footer className="text-[black] py-4 bg-[#F6CB3D]" >
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div>
        <p>Jim's Handyman Services &copy; 2024</p>
        <p>Phone: (253) 431-4507</p>
        </div>
        <div className='items-end'>
        <a href="mailto:jimsout11@gmail.com" className="hover:text-gray-800">
          <FaEnvelope size={21}/>
        </a>
        </div>
      </div>
      </footer>
    );
};

export default Footer;
